<template>
    <modal ref="modalModificarConfiguracion" titulo="Personalizar configuración" tamano="modal-lg">
        <div class="row justify-content-center f-15 my-3">
            <div class="col-auto">
                <p>Elija la configuración que desea personalizar</p>
            </div>
        </div>
        <div class="row justify-content-center my-3 d-none">
            <div class="col-8">
                <el-select v-model="selectedConfigurations" multiple filterable placeholder="Buscar configuración" size="small" class="w-100">
                    <el-option v-for="item in optionsConfiguraciones" :key="item.id" :label="item.label" :value="item.id" />
                </el-select>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-8 mb-3 px-0">
                <el-input v-model="searchConfigurations" size="small" placeholder="Buscar configuración">
                    <i slot="suffix" class="el-input__icon el-icon-search" />
                </el-input>
            </div>
            <div class="col-8 shadow29 br-4 py-3">
                  <el-checkbox-group v-model="checkListConfigurations">
                    <el-checkbox label="Configuración 1" class="w-100 border-bottom py-3"></el-checkbox>
                    <el-checkbox label="Configuración 2" class="w-100 border-bottom py-3"></el-checkbox>
                    <el-checkbox label="Configuración 3" class="w-100 border-bottom py-3"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <template #end-buttons>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="toggle"> Aceptar </button>
        </template>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            searchConfigurations:'',
            activeName:'',
            checkListConfigurations: [],
            optionsConfiguraciones: [
                {
                    id: 1,
                    label: 'Configuración 1'
                }, 
                {
                    id: 2,
                    label: 'Configuración 2'
                }, 
                {
                    id: 3,
                    label: 'Configuración 3'
                }, 
                {
                    id: 4,
                    label: 'Configuración 4'
                }, 
                {
                    id: 5,
                    label: 'Configuración 5'
                }
            ],
            selectedConfigurations:[],
        }
    },
    methods: {
        toggle(){
            this.$refs.modalModificarConfiguracion.toggle()
        },
        crearTarea(){
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.nearby-dates-notification{
    table{
        tbody{
            tr{
                td{
                    vertical-align: top;
                }
            }
        }
        
    }
}
</style>
