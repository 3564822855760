<template>
    <section class="calendario-ver-detalle-conf container-fluid">
        <navbar-admin icon="icon-calendar-multiselect" />
        <!-- info top new -->
        <info-top :id="infoTop.id" 
        :img="infoTop.img" 
        :equipo="infoTop.equipo" 
        :etapa="infoTop.etapa"
        :planMantenimiento="infoTop.planMantenimiento"
        :configuracion="infoTop.configuracion"
        :material="infoTop.material" />
        
        <div class="row listado-por-dia my-4 justify-content-center">
            <div class="col-11 my-auto">
                <div class="row justify-content-end pb-2">
                    <!-- filtro -->
                    <div class="col-auto icon-option my-auto pl-0 my-auto">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltrosEquipo">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div class="col-auto my-auto">
                        <button 
                            class="btn f-13 text-general bg-ef shadow29"
                            :class="permitAction('calendario.personalizar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('calendario.personalizar', personalizarConfiguracion)"
                        >
                            Personalizar configuración
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-11 calendario-ver-detalle-etapas-configuracion">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item v-for="(data, idx) in 3" :key="idx" :name="idx">
                        <template slot="title">
                            <div class="d-middle justify-content-between w-100">
                                <p class="pl-3 text-white">Configuración  {{ idx+1 }} </p>
                                <div class="d-none">
                                    <el-input v-model="searchMaterial" size="mini" placeholder="Buscar referencia, material..." />
                                </div>
                            </div>
                        </template>
                        <div class="row justify-content-center mx-0">
                            <div class="col-12">
                                <tabla-general :data="listadoCotizacionesVigentes">
                                    <el-table-column label="Referencia" prop="referencia" align="center">
                                        <template slot-scope="scope">
                                            <p class="f-12 text-muted2 cr-pointer" @click="verCotizacion(scope.row.id)">
                                                {{ scope.row.referencia }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Tipo" prop="tipo">
                                        <template slot-scope="scope">
                                            <p class="f-12 text-muted2 tres-puntos">
                                                {{ scope.row.tipo }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Materiales" prop="materiales" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <p class="f-12 text-muted2 tres-puntos f-600">
                                                {{ scope.row.materiales }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Plan de mantenimiento" prop="planMantenimiento" width="240">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.visible" class="d-middle cr-pointer shadow29 border br-4 px-2 py-1 position-relative">
                                                <p class="f-12 text-muted2">
                                                    {{ scope.row.planMantenimiento }}
                                                </p>
                                                <i class="icon-menu-down f-16 ml-auto" @click="scope.row.visible = !scope.row.visible" />
                                            </div>
                                            <div v-if="scope.row.visible" class="position-absolute section-mantenimientos bg-white shadow29 p-2">
                                                <el-input v-model="searchMaintenance" clear="w-100" placeholder="Buscar" size="mini" suffix-icon="el-icon-search" />
                                                <div class="py-1">
                                                    <p v-for="(data,id) in 4" :key="id" class="cr-pointer" @click="scope.row.visible = !scope.row.visible;createNewMaintenance();"> {{ id+1 }}) Plan de mantenimiento {{ id+1 }} </p>
                                                </div>
                                            </div>
                                            <div v-else class="d-middle cr-pointer shadow29 border br-4 px-2 py-1" @click="verMantenimiento">
                                                <p class="f-12 text-muted2">
                                                    {{ scope.row.planMantenimiento }}
                                                </p>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Acción" prop="estado" align="center" width="80">
                                        <template slot-scope="scope">
                                            <div class="d-middle-center icon-option">
                                                <i 
                                                class="f-18 icon-pencil"
                                                :class="permitAction('calendario.editar.plan.mantenimiento') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                @click="functionPermitAction('calendario.editar.plan.mantenimiento', () => {scope.row.visible = !scope.row.visible} )"
                                                />
                                            </div>
                                        </template>
                                    </el-table-column>
                                </tabla-general>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <modal-filtro-lateral ref="modalFiltroEquipos" />
        <modal-personalizar-configuracion ref="modalPersonalizarConfiguracion" />
        <modal-loading-new-maintenance ref="modalLoadingNewMaintenance" />
    </section>
</template>

<script>
import infoTop from './components/infoTop.vue'
import modalFiltroLateral from './partials/modalFiltroLateral.vue'
import modalPersonalizarConfiguracion from './partials/modalPersonalizarConfiguracion.vue'
import modalLoadingNewMaintenance from './partials/modalLoadingNewMaintenance.vue'
export default {
    components:{
        infoTop,
        modalFiltroLateral,
        modalPersonalizarConfiguracion,
        modalLoadingNewMaintenance,
    },
    data() {
        return {
            searchStage: '',
            searchMaterial: '',
            activeName: 0,
            infoTop:{
                id: 1,
                img: '/img/pruebas/equipo1.png',
                equipo: 'Reactor de leche fluidizado',
                etapa: 'Efecto 1',
                planMantenimiento: 'Plan de mantenimiento',
                configuracion: 'Configuración',
                material: 'Material'
            },
            listadoCotizacionesVigentes:[
                {
                    id: 1,
                    referencia: '0001',
                    tipo: 'Metal',
                    materiales: 'Plancha de acero al carbono de baja resistencia',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 2,
                    referencia: '0002',
                    tipo: 'Metal',
                    materiales: 'Acero al carbón estructural',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 1,
                    visible: false,
                },
                {
                    id: 3,
                    referencia: '0003',
                    tipo: 'Metal',
                    materiales: 'ASTM A 36M/A36',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 4,
                    referencia: '0004',
                    tipo: 'Metal',
                    materiales: 'ASTM A 285M/A285',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 5,
                    referencia: '0005',
                    tipo: 'Válvula',
                    materiales: 'CSA G40-21',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 6,
                    referencia: '0006',
                    tipo: 'Válvula',
                    materiales: 'ISO 630 Calidad D, C',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 7,
                    referencia: '0007',
                    tipo: 'Mecánico',
                    materiales: 'SAE J 1392 050 X',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 8,
                    referencia: '0008',
                    tipo: 'Metal',
                    materiales: 'Plancha de acero al carbono de baja resistencia',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 9,
                    referencia: '0009',
                    tipo: 'Inoxidable',
                    materiales: 'DN EN 10111 DD 12',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
                {
                    id: 10,
                    referencia: '00010',
                    tipo: 'Mecánico',
                    materiales: 'Plancha de acero al carbono de baja resistencia',
                    planMantenimiento: 'Plan de mantenimiento 1',
                    estado: 0,
                    visible: false,
                },
               
            ],
            searchMaintenance: '',
            showMaintenance: false,
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.ver.detalle',
            'calendario.ver.detalle.configuracion'
        ]);

    },
    methods: {
        abrirModalFiltrosEquipo(){
            this.$refs.modalFiltroEquipos.toggle()
        },
        personalizarConfiguracion(){
            this.$refs.modalPersonalizarConfiguracion.toggle()
        },
        verCotizacion(){
            //this.$router.push({ name: '' })
        },
        verMantenimiento(){
            this.$router.push({ name: 'calendario.ver.detalle.configuracion.mantenimiento', params: {id_mantenimiento: 1} })
        },
        verListadoMantenimientos(id){
            console.log(id);
            this.showMaintenance = !this.showMaintenance
            this.$refs.showMaintenance
        },
        createNewMaintenance(){
            console.log('create new maintenance');
            this.showMaintenance = !this.showMaintenance
            this.$refs.modalLoadingNewMaintenance.toggle()
        }
    },

}
</script>

<style lang="scss" scoped>
.calendario-ver-detalle-conf{
    .list-day{
        background-color: white;
    }
    .p-tb-4px{
        padding: 4px 0px;
    }
    .bg-ef{
        background: #EFEEEE;
    }
    .section-mantenimientos{
        top: 44px; width: 92%; background: white; z-index: 9999;
    }
}
</style>