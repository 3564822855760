<template>
    <section>
        <modal-lateral ref="abrirModalFiltrosEquipo" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 text-center">
                    <p class="text-5d f-600 f-13 pb-2 border-bottom">Elija el equipo</p>
                </div>
                <div class="col-12 my-3">
                    <el-checkbox-group v-model="checkList">
                        <el-checkbox v-for="(data, index) in listadoEquipos" :key="index" :label="data.nombre" class="border-bottom w-100" style="display: flex; align-items: center;">
                            <div class="d-middle py-3 w-100">
                                <img :src="data.img" alt="" width="50" height="50">
                                <p class="f-12 px-2"> {{ data.nombre }} </p>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="toggle"> Aceptar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            checkList: [],
            listadoEquipos:[
                {
                    id: 1,
                    img: '/img/pruebas/equipo1.png',
                    nombre: 'Reactor de lecho fluidizado'
                },
                {
                    id: 2,
                    img: '/img/pruebas/equipo2.png',
                    nombre: 'Spray Dryers'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            equipos: 'calendario/calendario/equipos',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_equipos: 'calendario/calendario/Action_get_equipos',
        }),
        async toggle(){
            await this.Action_get_equipos();
            this.$refs.abrirModalFiltrosEquipo.toggle()
        },
    },

}
</script>

<style>

</style>