<template>
    <modal ref="loadingNewMaintenance" tamano="modal-lg" titulo="Adjuntar archivos" adicional="Guardar" @adicional="save">
        <div class="row justify-content-center m-3 f-15 position-relative">
            <div class="progress" style="width: 80%; position: absolute; height: 15px; top: 72px;">
                <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100" style="width: 100%" />
            </div>
            <div class="col-4 text-center">
                <div class="wh-151 bg-f6 rounded-circle mx-auto d-middle-center">
                    <div class="d-middle-center rounded-circle wh-91 border3">
                        <svg style="width:50px;height:50px" viewBox="0 0 24 24"> <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /> </svg>
                    </div>
                </div>
                <p class="f-12 mt-2 w-50 mx-auto"> CARGANDO LOS DATOS </p>
            </div>
            <div class="col-4 text-center">
                <div class="wh-151 bg-f6 rounded-circle mx-auto d-middle-center">
                    <div class="d-middle-center rounded-circle wh-91 border3">
                        <svg style="width:50px;height:50px" viewBox="0 0 24 24"> <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /> </svg>
                    </div>
                </div>
                <p class="f-12 mt-2 w-75 mx-auto"> CARGANDO LOS ARCHIVOS </p>
            </div>
            <div class="col-4 text-center">
                <div class="wh-151 bg-f6 rounded-circle mx-auto d-middle-center">
                    <div class="d-middle-center rounded-circle wh-91 border3">
                        <svg style="width:50px;height:50px" viewBox="0 0 24 24"> <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /> </svg>
                    </div>
                </div>
                <p class="f-12 mt-2"> PLAN DE MANTENIMIENTO PERSONALIZADA APLICADA </p>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            active: 0
        }
    },
    methods: {
        toggle(){
            this.$refs.loadingNewMaintenance.toggle()
        },
        save(){
            this.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.border3{
    border: 3px solid;
}
</style>
